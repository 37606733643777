<template>
  <div class="checkout-order-details">
    <section>
      <div class="container is-fullhd">
        <h3 class="title is-3 has-text-centered pb-6">Tramitar pedido</h3>
        <div class="checkout-wrap">
          <tab-wrap></tab-wrap>
          <div class="columns content-checkout-wrap is-flex-wrap-wrap">
            <div class="column is-12 mb-6">
              <div class="columns is-variable is-8">
                <!-- LEFT COLUMN -->
                <div class="column is-8 left-column">
                    <div class="columns is-variable is-flex-wrap-wrap">
                    <div class="column is-12">
                      <h4 class="title is-size-3 txt-c-mobile">Revise su pedido y confírmelo a continuación</h4>
                      <p>Confirma tu pedido y sigue el siguiente paso en caso de que hayas solicitado una recogida, en breve recibirás un email de confirmación con todos los detalles.</p>
                    </div>
                    <hr v-if="!pickUpHasSameAddress">
                    <div class="columns control destination-wrap" v-if="!pickUpHasSameAddress">
                      <template v-for="location in locations">
                        <div class="column is-4" v-bind:key="location.id">
                          <label class="radio">
                            <input type="radio" name="pickup-address" :value="location.id" v-model="pickup_location_id">
                            <span class="has-text-weight-bold ml-3">{{location.address.name}}</span>
                            <p>
                              <strong>{{location.address.clinic_name}}</strong><br>
                              <!-- <strong>{{location.address.name}}</strong><br> -->
                              {{location.address.address1}}<br>
                              {{location.address.zip}} {{location.address.city}} ({{location.address.province}}), {{location.address.country}}<br>
                              {{location.address.phone}}<br><br>
                              <a class="is-underlined" href="#" @click.prevent="editPickupAddress(location)">Editar</a>
                            </p>
                          </label>
                        </div>
                      </template>
                    </div>
                  </div>

                  <!-- Shipment locations -->
                  <div class="columns is-variable is-3 is-flex-wrap-wrap">
                    <div class="column is-6 mb-1">
                      <div class="has-background-light recap-order-box" v-if="primaryLocation !== null">
                        <p class="pb-4"><strong class="txt-yellow is-uppercase">Dirección de envío</strong></p>
                        <p>
                          <strong>{{primaryLocation.address.name}}</strong><br>
                          {{primaryLocation.address.address1}}<br>
                          {{primaryLocation.address.zip}} {{primaryLocation.address.city}} ({{primaryLocation.address.province}}), {{primaryLocation.address.country}}<br>
                          Tel: {{primaryLocation.address.phone}}<br><br>
                          <strong>Envío ordinario</strong>
                        </p>
                      </div>
                    </div>
                    <div class="column mb-1 is-6">
                      <div class="has-background-light recap-order-box">
                        <p class="pb-4"><strong class="txt-yellow is-uppercase">Dirección de facturación</strong></p>
                        <p v-if="primaryLocation !== null">
                          <strong>{{clinic.company_name}}</strong><br>
                          <strong>{{primaryLocation.address.name}}</strong><br>
                          {{primaryLocation.address.address1}}<br>
                          {{primaryLocation.address.zip}} {{primaryLocation.address.city}} ({{primaryLocation.address.province}}), {{primaryLocation.address.country}}<br>
                          Tel: {{primaryLocation.address.phone}}<br><br>
                          <strong>VAT {{clinic.tax_id}}</strong>
                        </p>
                      </div>
                    </div>
                    <div class="column is-12 mb-1">
                      <div class="has-background-light recap-order-box">
                        <p class="pb-4"><strong class="txt-yellow is-uppercase">Método de pago</strong></p>
                        <p>
                          <strong>TODO Mastercard que termina con 4899</strong><br>
                          Cad. 9/24<br>
                          John Doe
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <div class="columns is-multiline">
                        <div class="column is-12">
                          <div class="field">
                            <label class="checkbox">
                              <input type="checkbox" v-model="isAddShippingAddressOpen">
                              Añadir una nueva dirección
                            </label>
                          </div>
                        </div>
                        <edit-address v-if="isAddShippingAddressOpen || isEditModeShipping" :is-edit-mode="isEditModeShipping" :location-to-edit="clinic_location_shipping" :callback="closeEditShipping"></edit-address>
                      </div>
                    </div>
                  </div>

                  <div class="columns is-multiline">
                    <div class="column is-6 is-offset-6 mb-5">
                      <button class="button is-primary is-fullwidth is-medium" @click="finalizeStep('/checkout/payment-methods')" :disabled="shipping_location_id === null">Pedir ahora</button>
                    </div>
                  </div>
                </div>

                <!-- RIGTH COLUMN -->
                <order-recap-sidebar></order-recap-sidebar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Footer from '@/components/Footer.vue'
import TabWrap from '@/components/checkout/TabWrap.vue'
import OrderRecapSidebar from '@/components/checkout/OrderRecapSidebar.vue'
import EditAddress from '@/components/Clinic/EditAddress.vue'

export default {
  name: 'Checkout',
  components: {
    Footer,
    TabWrap,
    OrderRecapSidebar,
    EditAddress
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      clinic: 'user/clinic',
      locations: 'clinic/locations'
    })
  },
  data () {
    return {
      shipping_location_id: null,
      billing_location_id: null,
      pickup_location_id: null,
      shippingMethods: [],
      pickUpHasSameAddress: true,
      requireInvoice: false,
      primaryLocation: null,
      // FORM
      clinic_location_pickup: null,
      isAddPickupAddressOpen: false,
      isEditModePickup: false,
      nif: '',
      // Ship
      clinic_location_shipping: null,
      isAddShippingAddressOpen: false,
      isEditModeShipping: false
    }
  },
  methods: {
    editPickupAddress (address) {
      this.isEditModePickup = true
      this.clinic_location_pickup = address
    },
    closeEditPickup () {
      this.isEditModePickup = false
      this.isAddPickupAddressOpen = false
      this.clinic_location_pickup = null
    },
    editShippingAddress (address) {
      this.isEditModeShipping = true
      this.clinic_location_shipping = address
    },
    closeEditShipping () {
      this.isEditModeShipping = false
      this.isAddShippingAddressOpen = false
      this.clinic_location_shipping = null
    },
    goTo (path) {
      this.$router.push({ path: path })
    },
    finalizeStep (path) {
      if (this.pickUpHasSameAddress) {
        this.pickup_location_id = this.shipping_location_id
      }
      this.$store.commit({
        type: 'clinic/SET_CURRENT_ORDER_SHIPMENTS',
        currentOrder: {
          shipping_location_id: this.shipping_location_id,
          billing_location_id: this.billing_location_id,
          pickup_location_id: this.pickup_location_id
        }
      })
      this.goTo(path)
    },
    setDefaultLocations () {
      if (this.locations.length > 0) {
        for (let i = 0; i < this.locations.length; i++) {
          if (this.locations[i].primary) {
            this.shipping_location_id = this.locations[i].id
            this.billing_location_id = this.locations[i].id
            this.pickup_location_id = this.locations[i].id
            this.primaryLocation = this.locations[i]
          }
        }
      } else {
        this.isAddShippingAddressOpen = true
      }
    },
    fetchLocations () {
      const self = this
      this.$store.dispatch('clinic/getLocations', {
        clinicId: this.clinic.id,
        cb: (data) => {
          console.log(data)
          self.setDefaultLocations()
        },
        cbError: (error) => {
          self.errorMessage = '¡Error!'
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error
            }
          })
        }
      })
    }
  },
  created () {
    console.log(this.user)
    console.log(this.clinic)
    this.fetchLocations()
  }
}
</script>

<style scoped lang="scss">
.checkout-order-details{
  width: 100%;
  section{
    width: 100%;
    padding: 60px 0px;
    .checkout-wrap{
      width: 100%;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .content-checkout-wrap{
        padding: 60px;
        .left-column{
          .bg-form{
            padding: 17px;
            border-radius: 8px;
            background-color: rgba(227, 145, 79, .15);
          }
          .pick-up-wrap{
            p{
              color: #E3914F;
            }
            .icon-alert{
              display: block;
              height: 46px;
              width: 46px;
              border-radius: 10px;
              background-color: #E3914F;
              text-align: center;
              font-size: 1.5rem;
              padding-top: 12px;
              &:before{
                color: #FFF;
              }
            }
          }
          .destination-wrap{
            flex-wrap: wrap;
          }
          .has-border-bottom{
            border-bottom: 1px solid #57567C;
          }

          .recap-order-box{
            min-height: 225px;
            border-radius: 8px;
            padding: 21px 28px;
            &.is-empty{
              position: relative;
              background-color: transparent;
              border: 3px solid #F6F4EF;
              button{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0px;
                margin: 0px;
                padding: 0px;
                background-color: transparent;
                cursor: pointer;
                &:hover{
                  span{
                    opacity: 1;
                  }
                }
                span{
                  opacity: .3;
                  font-size: 7rem;
                  transition: all .3s ease-out;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
